<template>
    <div>
        
      <v-layout wrap justify-center  pb-12>
  <v-flex xs12 sm10 md10 lg10 text-center pb-12 data-aos="zoom-in-up" data-aos-ease="ease"
  data-aos-duration="1500" data-aos-delay="500">
    <v-layout wrap justify-space-between>
     <v-flex xs12 sm12 md7 lg7 ><v-layout wrap>
        <v-flex xs12  sm12 md12 lg12 text-center  pb-12>
         <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded }">
        <v-card-title>
          <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
            'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
            'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
          }">
          Course Benefit
          </span>
        </v-card-title>
  
        <v-card-text style="text-align: justify;">
          
            <p style="font-family: JostRegular; color: #1A2421;font-size:15px;">
              
                From documents to presentations, your essential toolkit: MS Office skills open doors in any field.
                
                
              </p>
              
              
          
         
  
          <!-- Extra content to display when expanded -->
          
        </v-card-text>
        
        <!-- See More button -->
        
      </v-card>
    </v-flex>  
    
    <v-flex xs12  sm12 md12 lg12 text-center  pb-12>
        <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded }">
       <v-card-title>
         <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
           'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
           'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
         }">
         Related Certifications
         </span>
       </v-card-title>
 
       <v-card-text style="text-align: justify;">
         
           <p style="font-family: JostRegular; color: #1A2421">
               <span style="font-family: OutfitBold; color: #1A2421; font-size:18px">CDE - Certificate in Data Entry - 2 months</span><br>
               Develop data entry skills. Gain expertise in data organization, accuracy, and speed, essential for data management roles.
              <br> Softwares : Basics of MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing).
               
               <!-- <span style="font-family: OutfitBold; color: #1A2421 ;margin-left:10px">Softwares :</span> Basics of MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing). -->
             </p>
             
             
         <p style="font-family: JostRegular; color: #1A2421">
           <span style="font-family: OutfitBold; color: #1A2421; font-size:18px">CMOA- Certificate in Office Automation – 3 months </span><br>

           Enhance office automation skills. Enhance skills in document creation, spreadsheet management, and presentation software for efficiency. 
          <br>
          Softwares : MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing).
          <!-- <span style="font-family: OutfitBold; color: #1A2421 ;margin-left:10px">Softwares :</span> MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing). -->
          
          
       </p>

       <p style="font-family: JostRegular; color: #1A2421">
           <span style="font-family: OutfitBold; color: #1A2421; font-size:18px">DMOA – Diploma in Office Automation – 6 months</span><br>

           Master office automation tools. Acquire skills in document creation, spreadsheet management, and presentation software. Also cover Database Management to boost career prospects.
          <br>
          Softwares : MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing). 
          <!-- <span style="font-family: OutfitBold; color: #1A2421 ;margin-left:10px">Softwares :</span> MS Word, MS Excel, MS PowerPoint & ASM(Malayalam Typing). -->
          
       </p>
        
 
         <!-- Extra content to display when expanded -->
         
       </v-card-text>
       
       <!-- See More button -->
       
     </v-card>
   </v-flex>  
  </v-layout>
  </v-flex>
  <v-flex xs12 sm12 md5 lg5 pl-0 pl-xs-0 pl-sm-0 pl-md-5 pl-lg-10 pl-xl-10>
    <v-layout wrap justify-end>
        <v-flex xs12 sm12 md12 lg12>
  
  <v-card style="color: #1B6DC1;" class="pa-5">
    <v-card-title >
      <span  style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1;">
        Related Courses
      </span>
    </v-card-title>
    <v-flex pt-3 v-for="(item, i) in items" :key="i">
        <router-link style="text-decoration:none;"  :to="item.route">
      <v-card color="#E6EFF9" style="color: #1B6DC1;" class="pa-2 ">
  
        <v-layout wrap justify-center>
          <!-- Image on the left -->
          <v-flex xs3 >
            <v-img :src="item.img" width="300px" height="100px"></v-img>
          </v-flex>
  
          <v-flex xs9 pt-5>
            <v-card-text>
              <span style="font-family: OutfitBold; font-size: 18px; color: black">{{ item.title }}</span>
              <!-- Wrap the rating and span in a flex container -->
              <!-- <div style="display: flex; align-items: center;">
              
                <v-rating style="margin-left: -10px;" v-model="rating" background-color="orange lighten-3" color="orange" small half-increments></v-rating>
           
  
              </div> -->
  
            </v-card-text>
            <!-- <v-layout wrap justify-start>
              
              <v-flex xs4  pl-1>
  
                <v-icon small color="blue">mdi-clock-outline</v-icon>
                &nbsp; <span style="color: black; text-transform: capitalize; font-family:OutfitRegular;font-size:13px">{{ item.months }}</span>
  
              </v-flex>
  
          
              <v-flex xs8 pl-1>
  
                <v-icon small color="blue">mdi-book-open-outline</v-icon>
                &nbsp;<span small style="color: black; text-transform: capitalize;font-family:OutfitRegular; font-size:13px">{{ item.chapter }}</span>
  
              </v-flex>
            </v-layout> -->
          </v-flex>
        </v-layout>
  
      </v-card>
    </router-link>
    </v-flex>
  
  </v-card>
  
  </v-flex>
  </v-layout>
  </v-flex>
    </v-layout> 
  
  
  </v-flex>
  
  
  
  
        
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
  data() {
    return {
      isExpanded: false,
      
      // rating: 4,
      items: [
      {
          img: require("./../../assets/images/ger.png"),
          title: "German course - LEVELS A1-B2",
          route:"/coursesingleview"
          //   review: "4.5",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
       
        {
          img: require("./../../assets/images/tally.png"),
          title: "Tally Prime",
          route:"/tallypage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/multimedia.png"),
          title: "Multimedia Course",
          route:"/multymediapage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
    ],
    };
  },
  computed: {
    // Compute the filtered list based on isExpanded
    filteredListItems() {
      if (this.isExpanded) {
        return this.listItems;
      } else {
        return this.listItems.slice(0, 5);
      }
    },
  },
  methods: {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },
  },
  };
  </script>
  
  <style>
  .expanded .v-card__text > div {
  display: block; /* Adjust this as needed */
  }
  </style>y
  