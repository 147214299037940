import { render, staticRenderFns } from "./officeautodetails.vue?vue&type=template&id=73ca3886"
import script from "./officeautodetails.vue?vue&type=script&lang=js"
export * from "./officeautodetails.vue?vue&type=script&lang=js"
import style0 from "./officeautodetails.vue?vue&type=style&index=0&id=73ca3886&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports